import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, CircularProgress, Autocomplete, Divider,
    Card, CardContent, CardHeader, IconButton, List, ListItem, Grid, Chip, Box,
    Tooltip, Checkbox } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { useAppContext } from '../contexts/AppContext';
import { useAuth } from "../contexts/AuthContext";
import Markdown from 'markdown-to-jsx';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import config from '../config';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ProjectStrategyRAG = ({ projectData }) => {
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [response, setResponse] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [editable, setEditable] = useState(false);
    const [acceptanceCriteria, setAcceptanceCriteria] = useState([]);
    const [reports, setReports] = useState([]);
    const [filteredCriteria, setFilteredCriteria] = useState([]);
    const [selectedCriteria, setSelectedCriteria] = useState([]);
    const [showCriteria, setShowCriteria] = useState(false);
    const [criteriaSearchValue, setCriteriaSearchValue] = useState('');

    const [selectedContexts, setSelectedContexts] = useState({
        strategie: true,
        protokolle: true,
        vorgaben: true,
        akzeptanzkriterien: true,
        berichte: true,
        projektdetails: true
    });
    
    const { getAcceptanceCriteria, getSearchResultsInDocsStatelessV2, fetchReportResults, queryGenerativeModel } = useAppContext();
    const { currentTenant } = useAuth();

    // Example questions for the autocomplete
    const questions = [
        "Wie ist die qualitative Bewertung der Projektabwicklung und welche Handlungen müssen getätigt werden um die Qualitäten ggf. zu erhöhen?",
        "Wie können wir unsere Projektabwicklung verbessern?",
        "Was sind die strategischen Ziele für das nächste Quartal?",
        "Gibt es Risiken bei unseren laufenden Projekten?",
        "Wie wollen wir unseren Marktanteil ausbauen?",
        "Welche Verbesserungen können wir vornehmen, um die Effizienz zu steigern?"
    ];

    const max_elements = 5; // For Searching and ACs
    const max_depth = 3; // Match Count for Search

    // Fetch acceptance criteria when component loads
    useEffect(() => {
        const fetchAcceptanceCriteria = async () => {
            const criteria = await getAcceptanceCriteria();
            setAcceptanceCriteria(criteria);
            setFilteredCriteria(criteria);
        };
        fetchAcceptanceCriteria();
    }, [getAcceptanceCriteria]);

    useEffect(() => {
        const fetchReportData = async () => {
          try {
            //setLoading(true);
            const result = await fetchReportResults();
            // Get today's date in YYYY-MM-DD format for comparison
            const today = new Date().toISOString().split('T')[0];
            
            // Filter data by project and exclude reports created today
            const filteredData = result
                .filter(item => item.project === projectData.name && item.creation_date !== today)
                .sort((a, b) => new Date(b.creation_date) - new Date(a.creation_date)); // Sort by creation_date, most recent first
            
            // Filter out the last 5 reports (most recent)
            const lastNReportsFiltered = filteredData.slice(max_elements);

            setReports(lastNReportsFiltered);
          } catch (error) {
            console.error('Failed to fetch report results:', error);
          } finally {
            //setLoading(false);
          }
        };
    
        fetchReportData();
      }, [fetchReportResults]);

    const preprocessCriteria = (criteria) => {
        return criteria.map(c => {
            const requirements = c.return_schema?.requirements?.map(req => `${req.name}: ${req.description}`).join('; ') || '';
            return `Name: ${c.name}; Beschreibung: ${c.description}; Anforderungen: ${requirements}`;
        }).join(' ');
    };

    const handleAddQuestion = async () => {
        const strategy_directory = `/${currentTenant}/${projectData.name}/Strategie`;
        const protocol_directory = `/${currentTenant}/${projectData.name}/Protokolle`;
        const references_directory = `/${currentTenant}/Vorgaben Vorlagen und Richtlinien`;
    
        if (inputValue || selectedQuestions.length > 0 || selectedCriteria.length > 0) {
            setIsLoading(true);
            const updatedQuestions = [...selectedQuestions, ...(inputValue ? [inputValue] : [])];
            setSelectedQuestions(updatedQuestions);
    
            const searchString = updatedQuestions.join(', ');
    
            let strategy_matches = [];
            let protocol_matches = [];
            let references_matches = [];
    
            // Process data only for active contexts
            if (selectedContexts.strategie) {
                try {
                    strategy_matches = await getSearchResultsInDocsStatelessV2(
                        config.envs[config.active_env].domain + config.api_path,
                        max_elements, 
                        max_depth, 
                        strategy_directory, 
                        searchString
                    );
                } catch (error) {
                    console.error('Failed to fetch matches from Strategie:', error);
                }
            }
    
            if (selectedContexts.protokolle) {
                try {
                    protocol_matches = await getSearchResultsInDocsStatelessV2(
                        config.envs[config.active_env].domain + config.api_path,
                        max_elements,
                        max_depth,
                        protocol_directory,
                        searchString
                    );
                } catch (error) {
                    console.error('Failed to fetch matches from Protokolle:', error);
                }
            }
    
            if (selectedContexts.vorgaben) {
                try {
                    references_matches = await getSearchResultsInDocsStatelessV2(
                        config.envs[config.active_env].domain + config.api_path,
                        max_elements,
                        max_depth,
                        references_directory,
                        searchString
                    );
                } catch (error) {
                    console.error('Failed to fetch matches from Vorgaben:', error);
                }
            }
    
            const { distribution, ...adaptedProjectData } = projectData;
    
            const strategyMatchesString = strategy_matches.map(match =>
                match.text_sections.map(section => section.text).join(', ')
            ).join(', ');
            const protocolString = protocol_matches.map(match =>
                match.text_sections.map(section => section.text).join(', ')
            ).join(', ');
            const referencesString = references_matches.map(match =>
                match.text_sections.map(section => section.text).join(', ')
            ).join(', ');
    
            const criteriaString = selectedContexts.akzeptanzkriterien ? preprocessCriteria(selectedCriteria) : '';
    
            const reportsString = selectedContexts.berichte ? reports.map(report => {
                const documentNames = report.document && typeof report.document === 'object'
                    ? Object.values(report.document).map(doc => doc.name).join(', ')
                    : 'Keine Dokumente';
                return `Dokumente: ${documentNames}, Name: ${report.name}, Erstellungsdatum: ${report.creation_date}, Text: ${report.text}`;
            }).join('; ') : '';
    
            // Construct the messages array based on active contexts
            const messages = [
                { role: 'system', content: 
                    "You are a Question-answering assistant, specialized in the construction and real-estate industry. You will be provided with user questions (USER_QUERY) regarding strategic project decisions. The context information provided is dynamically controlled and can vary among some or all of these sources: Strategie, Protokolle, Vorgaben, Akzeptanzkriterien, Berichte and Projektdetails. You must only reference information from provided contexts, if no information is provided to make an informed response, then state what information you need. Include referenced sources in the response at the end, labeled 'Quellen:'. Include citations (specify the full document or source names if possible) within the response. Include the user query in the response at the beggining, labeled 'Frage:'. Provide the answer in German"
                }
            ];
    
             // Add messages only if the corresponding string is not empty
            if (selectedContexts.strategie && strategyMatchesString.trim() !== '') messages.push({ role: 'assistant', content: 'Strategie: ' + strategyMatchesString });
            if (selectedContexts.protokolle && protocolString.trim() !== '') messages.push({ role: 'assistant', content: 'Protokolle: ' + protocolString });
            if (selectedContexts.vorgaben && referencesString.trim() !== '') messages.push({ role: 'assistant', content: 'Vorgaben Vorlagen und Richtlinien: ' + referencesString });
            if (selectedContexts.akzeptanzkriterien && criteriaString.trim() !== '') messages.push({ role: 'assistant', content: 'Akzeptanzkriterien: ' + criteriaString });
            if (selectedContexts.berichte && reportsString.trim() !== '') messages.push({ role: 'assistant', content: 'Berichte: ' + reportsString });
            if (selectedContexts.projektdetails) messages.push({ role: 'assistant', content: 'Projektdetails: ' + JSON.stringify(adaptedProjectData) });

            messages.push({ role: 'user', content: 'USER_QUERY: ' + searchString });
    
            const queryResponse = await queryGenerativeModel(messages);
            setResponse(queryResponse || 'Ergebnisse konnten nicht generiert werden.');
            setIsLoading(false);
            setInputValue('');
        }
    };    
    

    const handleCriteriaSearch = (e) => {
        const searchValue = e.target.value.toLowerCase();
        setCriteriaSearchValue(searchValue);
        setFilteredCriteria(acceptanceCriteria.filter(criteria => criteria.name.toLowerCase().includes(searchValue)));
    };

    const handleCriteriaSelection = (criteria) => {
        const isSelected = selectedCriteria.some(item => item.name === criteria.name);
        if (isSelected) {
            setSelectedCriteria(selectedCriteria.filter(item => item.name !== criteria.name));
        } else if (selectedCriteria.length < max_elements) {
            setSelectedCriteria([...selectedCriteria, criteria]);
        }
    };    

    const handleClearCriteria = () => {
        setSelectedCriteria([]);
    };

    const handleSaveEdits = () => {
        setEditable(false);
    };

    const clearInputAndQuestions = () => {
        setSelectedQuestions([]);
        setInputValue('');
    };

    const handleSaveAsPDF = () => {
        const docDefinition = {
            content: [
                { text: 'Strategische Einblicke', style: 'header' },
                {
                    ul: selectedQuestions.map((question, index) => ({
                        text: `${index + 1}. ${question}`,
                        style: 'question'
                    }))
                },
                { text: 'Antwort:', style: 'subheader', margin: [0, 10, 0, 10] },
                { text: response, style: 'response', margin: [0, 0, 0, 10] }
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                subheader: {
                    fontSize: 15,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                question: {
                    margin: [0, 5, 0, 5]
                },
                response: {
                    fontSize: 12
                }
            }
        };

        pdfMake.createPdf(docDefinition).download('response.pdf');
    };

    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
            <CardHeader 
                title={
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, cursor: 'pointer' }}>
                        <AutoAwesomeIcon color="secondary" sx={{ verticalAlign: 'middle', marginRight: 1, marginBottom: 2 }} />
                        <Tooltip title={config.ui_messages.ai_disclaimer}>
                            <IconButton sx={{ padding: 0, marginRight: 2 }}> 
                                <InfoOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                        Strategische Einblicke
                    </Typography>
                } 
                sx={{ textAlign: 'left', paddingBottom: 0 }}
            />
            <CardContent>
                <Grid container spacing={2}>
                    {/* Row with subtitle and checkboxes */}
                    <Grid item xs={12} container alignItems="top" justifyItems='stretch'>
                        {/* Left Column */}
                        <Grid item xs={7}>
                            <Typography variant="subtitle2" align='left' sx={{ fontSize: '0.75rem', alignItems: 'flex-start' }}>
                                Eine Projektabwicklungsstrategie ist ein umfassender Plan, der festlegt, wie ein Projekt von Anfang bis Ende durchgeführt wird. Sie beinhaltet die Definition von Zielen, die Festlegung von Verfahren und die Zuteilung von Ressourcen, um die Projektziele zu erreichen. Diese Strategie soll sicherstellen, dass das Projekt effizient, innerhalb des vorgegebenen Zeitrahmens und Budgets abgewickelt wird. Zu den Hauptfragen und Feststellungen, die sich aus einer Projektabwicklungsstrategie ergeben können, gehören:
                            </Typography>
                            <List dense sx={{ fontSize: '0.75rem', alignItems: 'flex-start' }}>
                                <ListItem>
                                    <Box>
                                        <Typography variant="body2" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                                            Aufwandsschätzung
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.75rem'}}>
                                            Schätzung des Zeit- und Ressourcenaufwands, der zur Erreichung der Projektziele benötigt wird.
                                        </Typography>
                                    </Box>
                                </ListItem>
                                <ListItem>
                                    <Box>
                                        <Typography variant="body2" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                                            Abstimmungshilfen
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.75rem'}}>
                                            Definition der Mechanismen zur Unterstützung der Koordination zwischen verschiedenen Teams oder Abteilungen, um sicherzustellen, dass alle aufeinander abgestimmt sind und effizient arbeiten.
                                        </Typography>
                                    </Box>
                                </ListItem>
                                <ListItem>
                                    <Box>
                                        <Typography variant="body2" sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                                            Praxisnahe Strategiedurchführung
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontSize: '0.75rem'}}>
                                            Überwachung und Anpassung der Strategie basierend auf der Projektfortschritt und etwaigen Herausforderungen.
                                        </Typography>
                                    </Box>
                                </ListItem>
                            </List>




                        </Grid>

                        {/* Vertical Divider */}
                        <Grid item xs={1} sx={{paddingRight:2}}>
                            <Divider orientation="vertical" flexItem sx={{ height: '100%' }} />
                        </Grid>

                        {/* Right Column */}
                        <Grid item xs={4} justifyContent='flex-end'>
                            {/* Smaller and right-aligned title */}
                            <Typography variant="subtitle2" align="right" sx={{ fontSize: '0.75rem', marginBottom: 2 }}>
                                Wählen Sie, welche Daten als Kontext verwendet werden sollen:
                            </Typography>
                            
                            <Grid container spacing={2}>
                                <Grid item xs={12} container justifyContent="flex-end" alignItems="center" sx={{ padding: '0px !important' }}>
                                    <Typography variant="body2" sx={{ marginRight: 1, fontSize: '0.75rem' }}>Strategie</Typography>
                                    <Checkbox 
                                        checked={selectedContexts.strategie} 
                                        onChange={() => setSelectedContexts(prev => ({ ...prev, strategie: !prev.strategie }))} 
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12} container justifyContent="flex-end" alignItems="center" sx={{ padding: '0px !important' }}>
                                    <Typography variant="body2" sx={{ marginRight: 1, fontSize: '0.75rem' }}>Protokolle</Typography>
                                    <Checkbox 
                                        checked={selectedContexts.protokolle} 
                                        onChange={() => setSelectedContexts(prev => ({ ...prev, protokolle: !prev.protokolle }))} 
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12} container justifyContent="flex-end" alignItems="center" sx={{ padding: '0px !important' }}>
                                    <Typography variant="body2" sx={{ marginRight: 1, fontSize: '0.75rem' }}>Vorgaben Vorlagen und Richtlinien</Typography>
                                    <Checkbox 
                                        checked={selectedContexts.vorgaben} 
                                        onChange={() => setSelectedContexts(prev => ({ ...prev, vorgaben: !prev.vorgaben }))} 
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12} container justifyContent="flex-end" alignItems="center" sx={{ padding: '0px !important' }}>
                                    <Typography variant="body2" sx={{ marginRight: 1, fontSize: '0.75rem' }}>Akzeptanzkriterien</Typography>
                                    <Checkbox 
                                        checked={selectedContexts.akzeptanzkriterien} 
                                        onChange={() => setSelectedContexts(prev => ({ ...prev, akzeptanzkriterien: !prev.akzeptanzkriterien }))} 
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12} container justifyContent="flex-end" alignItems="center" sx={{ padding: '0px !important' }}>
                                    <Typography variant="body2" sx={{ marginRight: 1, fontSize: '0.75rem' }}>Berichte</Typography>
                                    <Checkbox 
                                        checked={selectedContexts.berichte} 
                                        onChange={() => setSelectedContexts(prev => ({ ...prev, berichte: !prev.berichte }))} 
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12} container justifyContent="flex-end" alignItems="center" sx={{ padding: '0px !important' }}>
                                    <Typography variant="body2" sx={{ marginRight: 1, fontSize: '0.75rem' }}>Projektdetails</Typography>
                                    <Checkbox 
                                        checked={selectedContexts.projektdetails} 
                                        onChange={() => setSelectedContexts(prev => ({ ...prev, projektdetails: !prev.projektdetails }))} 
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Autocomplete
                            multiple
                            id="tags-filled"
                            options={questions}
                            freeSolo
                            value={selectedQuestions}
                            onChange={(event, newValue) => setSelectedQuestions(newValue)}
                            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Wählen oder eine Frage eingeben" 
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {isLoading ? <CircularProgress size={24} /> : null}
                                                {selectedQuestions.length > 0 || inputValue ? (
                                                    <IconButton onClick={clearInputAndQuestions}>
                                                        {/* <ClearIcon /> */}
                                                    </IconButton>
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} container spacing={2} alignItems="center" justifyContent={'right'}>

                        {/* Kriterien suchen TextField in the same row */}
                        {showCriteria && selectedContexts.akzeptanzkriterien && (
                            <Grid item xs>
                                <TextField 
                                    label="Kriterien suchen" 
                                    variant="outlined" 
                                    value={criteriaSearchValue} 
                                    onChange={handleCriteriaSearch} 
                                    fullWidth 
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={handleClearCriteria}>
                                                <ClearIcon />
                                            </IconButton>
                                        ),
                                    }}
                                />
                            </Grid>
                        )}
                        {/* Akzeptanzkriterien Einfügen Button - Only visible if the acceptance criteria context is active */}
                        {selectedContexts.akzeptanzkriterien && (
                            <Grid item>
                                <Button 
                                    variant={showCriteria ? "contained" : "outlined"} // Change variant when showCriteria is true
                                    onClick={() => setShowCriteria(!showCriteria)} 
                                    sx={{marginTop:2, marginBottom:2}}
                                >
                                    Akzeptanzkriterien Einfügen
                                </Button>
                            </Grid>
                        )}
                    </Grid>

                    {/* List of Criteria with Scroll, displayed below the search field */}
                    {showCriteria && (
                        <Grid item xs={12}>
                            <Typography variant="body2" color="textSecondary" align="right">
                                Maximale Anzahl von Kriterien: {max_elements}
                            </Typography>
                            <List
                                sx={{
                                    maxHeight: '14rem', // Approximate height for 7 lines (adjust if needed based on font size)
                                    overflowY: 'auto',   // Enable vertical scrolling
                                    border: '1px solid #ddd', // Optional: add a border for better visibility
                                    padding: '0',
                                    marginTop: 2
                                }}
                            >
                                {filteredCriteria.map((criteria, index) => (
                                    <ListItem key={index}>
                                        <Checkbox 
                                            checked={selectedCriteria.some(item => item.name === criteria.name)} 
                                            onChange={() => handleCriteriaSelection(criteria)} 
                                            disabled={selectedCriteria.length >= max_elements && !selectedCriteria.some(item => item.name === criteria.name)}
                                        />
                                        {criteria.name}
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>
                    )}

                    {/* Row to display selected criteria as Chips */}
                    {selectedContexts.akzeptanzkriterien && (
                    <Grid item xs={12}>
                        {selectedCriteria.length > 0 && (
                            <Grid container spacing={1} justifyContent="flex-end">
                                {selectedCriteria.map((criteria, index) => (
                                    <Grid item key={index}>
                                        <Chip 
                                            label={criteria.name} 
                                            onDelete={() => handleCriteriaSelection(criteria)} // Allow removing the criteria by clicking the delete icon
                                            color="success"
                                            variant="outlined"
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Grid>
                    )}

                    <Grid item xs={12} container justifyContent="flex-end">
                        
                        {editable && (
                            <Button
                                variant="outlined"
                                onClick={handleSaveEdits}
                                sx={{ marginRight: 1  }}
                            >
                                Anpassungen Speichern
                            </Button>
                        )}
                        {!editable && response && !isLoading && (
                            <Button variant="outlined" onClick={() => setEditable(true)} sx={{ marginRight: 1 }}>
                                Antwort Anpassen
                            </Button>
                        )}
                        {!editable && response && !isLoading && (
                            <Button variant="outlined" onClick={handleSaveAsPDF} sx={{ marginLeft: 1 }}>
                                Als PDF Speichern
                            </Button>
                        )}
                        {!editable && (
                            <Button variant="contained" color="primary" onClick={handleAddQuestion} disabled={isLoading || selectedQuestions.length === 0 && inputValue === ''} sx={{ marginLeft: 1 }}>
                                Frage stellen
                            </Button>
                        )}
                        
                    </Grid>
                    <Grid item xs={12}>
                        {!isLoading && (
                           editable ? (
                            <TextField
                                align='left'
                                fullWidth
                                multiline
                                rows={20}
                                variant="outlined"
                                value={response}
                                onChange={(e) => setResponse(e.target.value)}
                                InputProps={{ readOnly: !editable }}
                            />
                        ) : (
                            <Markdown align='left'>{response}</Markdown>
                        )
                    )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ProjectStrategyRAG;