import React, { useState, useRef } from 'react';
import {
  Modal, Box, Typography, TextField, Button, IconButton, CircularProgress, Chip, Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { useAppContext } from '../contexts/AppContext';

import config from '../config';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ProtocolModal = ({ open, onClose }) => {
  const [protocolContent, setProtocolContent] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [isExtracting, setIsExtracting] = useState(false);
  const [audioFile, setAudioFile] = useState(null);
  const [audioLabel, setAudioLabel] = useState('');
  const [extractedEntities, setExtractedEntities] = useState({ human_readable: '', entities: [] });
  const [isEditing, setIsEditing] = useState(true);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const mediaStreamRef = useRef(null);

  const { queryGenerativeModel } = useAppContext();

  const handleProtocolContentChange = (event) => {
    setProtocolContent(event.target.value);
  };

  const handleSavePdf = () => {
    const now = new Date();
    const fileName = `Protokoll von ${now.toLocaleDateString()} ${now.toLocaleTimeString()}`;
    const docDefinition = {
      content: [
        { text: fileName, style: 'header' },
        protocolContent,
        /* { text: 'Extrahierte Entitäten und Sentiment Analyse:', style: 'subheader' }, */
        extractedEntities.human_readable,
      ],
    };
    pdfMake.createPdf(docDefinition).download(`${fileName}.pdf`);
  };

  const handleStartRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaStreamRef.current = stream;
    mediaRecorderRef.current = new MediaRecorder(stream);
    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };
    mediaRecorderRef.current.start();
    setIsRecording(true);
  };

  const handleStopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        const now = new Date();
        setAudioLabel(`Aufnahme von ${now.toLocaleDateString()} ${now.toLocaleTimeString()}`);
        setAudioFile(audioBlob);
        audioChunksRef.current = [];
        setIsRecording(false);

        if (mediaStreamRef.current) {
          mediaStreamRef.current.getTracks().forEach(track => track.stop());
        }
      };
    }
  };

  const handleTranscribeAudio = async () => {
    if (audioFile) {
      const formData = new FormData();
      formData.append('file', new File([audioFile], "audio.wav"));
      formData.append('model', 'whisper-1');
      formData.append('language', 'de');

      const dec_p = atob(config.oai);
      const dec_k = atob(config.k_experimental);
      
      setIsTranscribing(true);
      try {
        const response = await axios.post('https://api.openai.com/v1/audio/transcriptions', formData, {
          headers: {
            'Authorization': `Bearer ${dec_p}${dec_k}`,
            'Content-Type': 'multipart/form-data',
          },
        });

        const transcriptionText = response.data.text;
        setProtocolContent((prevContent) => `${prevContent}\n${transcriptionText}`);
      } catch (error) {
        console.error('Error transcribing audio:', error);
      }
      setIsTranscribing(false);
    }
  };

  const handleExtractEntities = async () => {
    if (protocolContent) {
      setIsExtracting(true);
      try {
        const response = await queryGenerativeModel([
          { role: 'system', content: 'You are an assistant that extracts tasks from protocol texts. Provide all parts of the answer in German.' },
          { role: 'user', content: `Extract all tasks from the following protocol text. Each task should include the assignment of work to people or roles, and the due date if available. Return the result as a JSON object with the fields "human_readable" and "entities". The "entities" field should be an array of objects with the schema: [{ "word": "example", "label": "TASK|ROLE|DATE" }]. Include a SUMMARY of the transcription in the "human_readable" field. Include a description of the extracted data in the "human_readable" field. Format the "human_readable" field as markdown. Protocol text: ${protocolContent}` }
        ], 'json_object');

        console.log(response);
        /* const response = await axios.post('https://api.openai.com/v1/chat/completions', {
          model: 'gpt-4o',
          response_format: {"type": 'json_object'},
          messages: [
            { role: 'system', content: 'You are an assistant that extracts tasks from protocol texts. Provide all parts of the answer in German.' },
            { role: 'user', content: `Extract all tasks from the following protocol text. Each task should include the assignment of work to people or roles, and the due date if available. Return the result as a JSON object with the fields "human_readable" and "entities". The "entities" field should be an array of objects with the schema: [{ "word": "example", "label": "TASK|ROLE|DATE" }]. Include a SUMMARY of the transcription in the "human_readable" field. Include a description of the extracted data in the "human_readable" field. Format the "human_readable" field as markdown. Protocol text: ${protocolContent}` }
          ],
          temperature: 0.5,
        }, {
          headers: {
            'Authorization': `Bearer ${config.oaikey}`,
            'Content-Type': 'application/json',
          },
        }); */

        /* const result = response.data.choices[0].message.content.trim(); */
        const jsonResponse = JSON.parse(response);

        setExtractedEntities(jsonResponse);
      } catch (error) {
        console.error('Error extracting entities:', error);
      }
      setIsExtracting(false);
    }
  };

  const handleClose = () => {
    setProtocolContent('');
    setIsRecording(false);
    setIsTranscribing(false);
    setIsExtracting(false);
    setAudioFile(null);
    setAudioLabel('');
    setExtractedEntities({ human_readable: '', entities: [] });
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach(track => track.stop());
    }
    if (onClose) {
      onClose();
    }
  };

  const labelColors = {
    TASK: '#FFCDD2',
/*     PERSON: '#C8E6C9', */
    ROLE: '#BBDEFB',
    DATE: '#FFF9C4',
  };

  const getColorByLabel = (label) => labelColors[label] || 'transparent';

  const highlightText = (text, entities) => {
    if (!entities || !entities.entities) return text;
  
    let highlightedText = text;
    entities.entities.forEach(({ word, label }) => {
      const regex = new RegExp(`(${word})`, 'g');
      highlightedText = highlightedText.replace(
        regex,
        `<span style="
          background-color: ${getColorByLabel(label)};
          padding: 2px 4px;
          border-radius: 4px;
          display: inline-block;
          margin: 0 2px;
        ">${word}</span>`
      );
    });
  
    return highlightedText;
  };
  
  

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)', 
        width: '50vw', 
        height: '80vh', 
        bgcolor: 'background.paper', 
        border: '2px solid #000', 
        boxShadow: 24, 
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}>
        <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
          <CloseIcon />
        </IconButton>
        <Box>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Neues Protokoll erfassen
          </Typography>
          {extractedEntities.human_readable && (
          <Stack direction="row" spacing={1} sx={{ marginTop: 2 }}>
            {Object.keys(labelColors).map((label) => (
              <Chip
                key={label}
                label={label}
                style={{ backgroundColor: labelColors[label], color: '#000' }}
              />
            ))}
          </Stack>
          )}
          {isEditing ? (
            <TextField
              label="Protokoll"
              multiline
              rows={6}
              value={protocolContent}
              onChange={handleProtocolContentChange}
              fullWidth
              margin="normal"
            />
          ) : (
            <Box
              sx={{ 
                /* p: 2,  */
                /* border: '1px solid rgba(0, 0, 0, 0.23)',  */
                borderRadius: '4px', 
                overflow: 'auto',
                whiteSpace: 'pre-wrap'
              }}
              dangerouslySetInnerHTML={{
                __html: highlightText(protocolContent, extractedEntities),
              }}
            />
          )}
          {extractedEntities.human_readable && (
          <Button
            variant="contained"
            sx={{ marginTop: 1 }}
            onClick={() => setIsEditing(!isEditing)}
          >
            {isEditing ? 'Highlight View' : 'Edit'}
          </Button>
          )}
          {audioFile && (
            <div>
              <Typography variant="body2">{audioLabel}</Typography>
              <Button variant="contained" onClick={handleTranscribeAudio} sx={{ marginTop: 1 }} disabled={isTranscribing}>
                {isTranscribing ? <CircularProgress size={24} /> : 'Audio transkribieren'}
              </Button>
              {protocolContent && (
                <Button variant="contained" onClick={handleExtractEntities} sx={{ marginTop: 1, marginLeft: 2 }} disabled={isExtracting}>
                  {isExtracting ? <CircularProgress size={24} /> : 'Entitäten extrahieren'}
                </Button>
              )}
            </div>
          )}
          {extractedEntities.human_readable && (
            <Box sx={{ marginTop: 2 }}>
              <Typography variant="h6" component="h3">
                Extrahierte Entitäten
              </Typography>
              <ReactMarkdown>{extractedEntities.human_readable}</ReactMarkdown>
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          {protocolContent && (
            <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={handleSavePdf}>
              als pdf speichern
            </Button>
          )}
          {isRecording ? (
            <Button variant="contained" color="error" startIcon={<StopIcon />} onClick={handleStopRecording} sx={{ animation: 'blinking 1s infinite' }}>
              Aufnahme stoppen
            </Button>
          ) : (
            <Button variant="contained" color="primary" startIcon={<MicIcon />} onClick={handleStartRecording}>
              Aufnahme beginnen
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
  
};

export default ProtocolModal;
